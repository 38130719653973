// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apollo-one-racehorse-js": () => import("./../../../src/pages/apollo-one-racehorse.js" /* webpackChunkName: "component---src-pages-apollo-one-racehorse-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-horseracing-syndicate-newmarket-js": () => import("./../../../src/pages/horseracing-syndicate-newmarket.js" /* webpackChunkName: "component---src-pages-horseracing-syndicate-newmarket-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mayson-kalon-brama-js": () => import("./../../../src/pages/mayson-kalon-brama.js" /* webpackChunkName: "component---src-pages-mayson-kalon-brama-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-space-trooper-js": () => import("./../../../src/pages/space-trooper.js" /* webpackChunkName: "component---src-pages-space-trooper-js" */)
}

